<template>
  <div class="home">
    <div>12346545555555544444444</div>

  </div>
</template>

<script>

                                  
export default {
  name:"home",
  components: {
  
  },
}
</script>
